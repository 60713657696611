


jQuery(document).ready(function() {
	
	$('.nav-mobile .menu-btn').click(function() {
		var menus = $(this).closest('.nav-mobile');
		menus.toggleClass('menu-open');
		return false;
	});
	
	$('.nav-mobile .menu-btn-close').click(function() {
		var menus = $(this).closest('.nav-mobile');
		menus.toggleClass('menu-open');
		return false;
	});
	
	$('.nav-mobile .menu-item a').click(function() {
		var menus = $(this).closest('.nav-mobile');
		menus.removeClass('menu-open');
		return true;
	});
});



$(document).mouseup(function(e) {
	var container = $('.nav-mobile');
	// if the target of the click isn't the container nor a descendant of the container
	if (!container.is(e.target) && container.has(e.target).length === 0) {
		// container.hide();
		container.removeClass('menu-open');
	}
});
